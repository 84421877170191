import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import DesignedForRealtors from "../components/Repeating/DesignedForRealtors";
import AccelerateYourBusiness from "../components/Repeating/AccelerateYourBusiness";
import Testimonials from "../components/Repeating/Testimonials";
import Reviews from "../components/Repeating/Reviews";
import RecentPosts from "../components/Repeating/RecentPosts";
import SliderLogos1 from "../components/Slider/SliderLogos1";
import SliderLogos2 from "../components/Slider/SliderLogos2";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";

const Page = ({ data }) => {
  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Real Estate Workflow Automation Software | Real Synch"
        description="Easily integrate and automate your real estate team's workflow with Real Synch's real estate workflow automation software. Find out how simple it is to set up."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-16 bg-primary-900 pt-24 pb-14 md:mb-28 md:pt-40 md:pb-26">
        <div className="container">
          <div className="grid items-center gap-y-12 md:grid-cols-2 md:gap-x-14 lg:gap-x-28">
            <div className="text-center md:text-left">
              <p className="mb-5 font-heading text-mobile-7xl font-extrabold text-white md:text-7xl">
                Real automation for{" "}
                <span className="gradient-text">real estate agents.</span>
              </p>
              <p className="text-3xl text-white/80 md:mb-8 md:text-4xl">
                Connect your systems. Cut your costs. Close more business.
              </p>
              <ButtonSolid
                altStyle={2}
                href="/pricing/"
                text="Start for Free"
              />
            </div>
            <div>
              <div className="relative">
                <StaticImage
                  src="../images/1.0 Homepage/1.2-Hero-Asset-Part-2.png"
                  loading="eager"
                  className="relative z-10 w-full"
                />
                <svg
                  className="rotating absolute inset-0 m-auto w-[88%]"
                  viewBox="0 0 480 480"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="240"
                    cy="240"
                    r="238.5"
                    stroke="url(#paint0_linear_1147_2430)"
                    strokeWidth="3"
                    strokeDasharray="6 6"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1147_2430"
                      x1="0"
                      y1="480"
                      x2="567.523"
                      y2="150.957"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#673DEC" />
                      <stop offset="1" stopColor="#51E7FF" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ValueProps headingLevel="h1" className="mb-20 md:mb-32" />

      <section className="mb-20 overflow-x-hidden rounded-tl-[120px] rounded-br-[120px] bg-primary-900 py-20 md:mb-28 md:rounded-tl-[240px] md:rounded-br-[240px] md:py-32">
        <div className="container">
          <header className="mx-auto mb-12 max-w-[800px] text-center md:mb-20">
            <h2 className="text-white">
              Easily Connect What You’re Already Using
            </h2>
            <p className="text-white/80">
              Real Synch integrates the systems you use every day, and we
              continually partner with new providers to help you connect every
              tool you’re using.
            </p>
          </header>

          <div className="mb-10 md:mb-20">
            <SliderLogos1>
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/BoomTown.png"
                loading="lazy"
                width={294}
                className="max-w-[148px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Brivity.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Chime.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/dotloop.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Evabot.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Firepoint.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Follow Up Boss.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />

              <StaticImage
                src="../images/1.0 Homepage/Logo slider/HomeLight.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/HubSpot.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/LionDesk.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
            </SliderLogos1>

            <SliderLogos2>
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/OjoLabs.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Opcity.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/RealGeeks.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Salesforce.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Showcase IDX.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Sierra.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Sisu.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />

              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Skyslope.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Structurely.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/VoicePad.png"
                loading="lazy"
                width={294}
                className="max-w-[128px] md:max-w-[294px]"
              />
            </SliderLogos2>
          </div>

          <div className="grid justify-center gap-y-6 md:flex md:gap-y-0 md:space-x-6">
            <ButtonGhost
              altStyle={2}
              href="/real-estate-software-integrations/"
              text="Learn More"
            />
            <ButtonSolid
              altStyle={2}
              href="/partner-pricing/"
              text="Become a Partner"
            />
          </div>
        </div>
      </section>

      <DesignedForRealtors />
      <AccelerateYourBusiness />
      <Testimonials />
      <Reviews />
      <RecentPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    business: file(relativePath: { eq: "home/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
  }
`;

export default Page;
