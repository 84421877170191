import React from "react";
import Slick from "react-slick";
import styled from "@emotion/styled";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled.div`
  .slick-list {
    ${tw`overflow-visible`}
  }
`;

const Slider = ({ children }) => {
  const sliderSettings = {
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    dots: false,
    arrows: false,
    infinite: true,
    rtl: true,
  };

  return (
    <StyledSlider>
      <Slick {...sliderSettings}>{children}</Slick>
    </StyledSlider>
  );
};

export default Slider;
