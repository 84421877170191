import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ValueProps = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: { eq: "1.0 Homepage/2.0 Integrate Your Tech Stack.svg" }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: { eq: "1.0 Homepage/2.1 Reduce Your Team’s Workload.svg" }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: {
          eq: "1.0 Homepage/2.2 Start Seeing The Big Picture.svg"
        }
      ) {
        publicURL
      }
      line: file(relativePath: { eq: "1.0 Homepage/dotted-line.svg" }) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "Integrate Your Tech Stack",
      text: "Real Synch connects the systems you’re already using. There’s no steep learning curve or new processes to learn.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "Reduce Your Workload",
      text: "Once you integrate, there’s no need to manually reenter the same data repeatedly across different systems.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "Start Seeing The Big Picture",
      text: "With Real Synch, you can stop logging into multiple systems to view your leads, activities, and transaction data.",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mx-auto mb-12 max-w-[680px] md:mb-20 md:text-center">
          <HeadingTag>Real Estate Workflow Automation Software</HeadingTag>
          <p>
            Easily integrate and automate your real estate team’s workflow with
            Real Synch—smart, adaptive technology designed for busy real estate
            agents and their teams. Stop doing double work and boost your
            production from lead to close.
          </p>
        </header>

        <div className="relative z-10 grid gap-y-12 md:grid-cols-3 md:gap-x-10 lg:gap-x-20">
          <img
            src={data.line.publicURL}
            alt="Dotted line"
            className="absolute left-0 hidden w-full md:block"
          />
          {content.map((content, i) => {
            return (
              <div key={i} className="text-center">
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mx-auto mb-10"
                />
                <div>
                  <h3 className="heading-four mb-3">{content.heading}</h3>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
